body {
	// background-color: $lt-grey;
}


.pagetitle {
	background-color: $lt-grey;
	padding: 20px 0;
	position: relative;
	h1 {
		padding: 0;
		margin: 0;
	}
	.breadcrumbs {
		a {
			color: $main-color-one !important;
		}
	}
	.hex-row {
		&.bottom {
			top: auto;
			bottom: -50%;
			background-position: top center;
			background-image: url(../images/hex-overlay-bottom-gray.svg) !important;
		}
	}
	+ .page-content {
		.page-inner {
			.sidebar {
				padding-top: 100px;
			}
			.content-area {
				padding-top: 100px;
			}
		}
	}
}

