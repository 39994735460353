.important-dates-sliders {
    position: relative;
    &:before {
        display: block;
        content: '';
        width: 200%;
        height: 2px;
        position: absolute;
        top: 15px;
        margin: 0 auto;
        background-color: $main-color-one;
    }
    .item {
        min-height: rem(150);
        padding: 0 20px;
        .icon {
            display: block;
            text-align: center;
            width: 30px;
            height: 30px;
            margin: 0 auto 30px auto;
            &:before {
                display: block;
                content: '';
                width: 30px;
                height: 30px;
                background-image: url(../images/hexagon.svg);
                background-size: 30px 30px;
                background-repeat: no-repeat;
            }
        }
        .title {
            display: block;
            text-align: center;
            font-family: $serif-font;
            font-size: rem(24);
            line-height: rem(24);
        }
        .detail {
            display: block;
            text-align: center;
        }
        &.first {
            .icon {
                &:before {
                    background-image: url(../images/star.svg);
                }
            }
        }
    }
    .slick-prev, .slick-next {
        &:before {
            font-size: 30px;
            top: calc(50% + 10px);
        }
    }
}