.slab {
    position: relative;
    @import 'components/slabs/accordion';
    @import 'components/slabs/button_cloud';
    @import 'components/slabs/buttons';
    @import 'components/slabs/icons';
    @import 'components/slabs/heros';
    @import 'components/slabs/hex_teaser';
    @import 'components/slabs/image_cards';
    @import 'components/slabs/important_dates';
    @import 'components/slabs/program_finder';
    @import 'components/slabs/stats';
    @import 'components/slabs/text_buttons';
    @import 'components/slabs/upcoming_events';
    @import 'components/slabs/video_embed';


    @import 'components/slabs/hex';
    @import 'components/slabs/hex_row';
    @import 'components/slabs/hex_quote';
    @import 'components/slabs/hex_overlays';
    @import 'components/slabs/teasers';
    @import 'components/slabs/two_columns';
    &.constrain {
        @include container;
        border: 10px solid red;
    }
}
