ul.jump-nav {
    list-style: none;
    text-align: center;
    margin: 0;
    position: relative;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid $white;
    background-color: $xlt-grey;
    display: flex;
    flex-wrap: wrap;
    @include media(">=md") {
        border-bottom: none;
        padding: 0;
        text-align: center;
        padding: 0;
        // position: absolute;
        // top: rem(-54);
        // right: rem(300);
    }
    li {
        list-style: none;
        padding-left: 0;
        position: relative;
        flex: 1;
        &:nth-child(2) {
            &:after {
                display: none;
            }
        }
        @include media(">=420px") {
            &:nth-child(2) {
                &:after {
                    display: block;
                }
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        a {
            padding: 8px 12px;
            text-transform: none;       
            color: $dk-grey;
            font-size: 16px;
            display: block;
            text-decoration: none;
            position: relative;
            z-index: 1;
            border-bottom: 5px solid transparent;
            transition: all .3s ease-in-out;
            &:hover {
                transition: all .3s ease-in-out;
                border-bottom: 5px solid $dk-grey;
                color: $main-color-one;
            }
            img {
                max-width: 20px;
                max-height: 20px;
                margin: 0 auto;
            }
            @include media(">=md") {
                display: block;
                width: auto;
                height: 100%;
                font-size: rem(12);
                border: none;
                text-decoration: none;
                font-weight: 400;
                padding: 10px;
                border-bottom: 5px solid transparent;
                &:hover {
                    background-color: $lt-grey;
                }
                &:after {
                    display: none;
                }
                .linktext {
                    display: block;
                }
            }
            img {
                max-width: 40px;
                max-height: 30px;
                margin: 0 auto;
            }
        }
        @include media(">=md") {
            &:after {
                display: none;
            }
            &:nth-child(2) {
                &:after {
                    display: none;
                }
            }
        }
    }
}



