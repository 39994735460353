.button-cloud {
	text-align: center;
	a {
		display: inline-block;
		padding: 5px 10px;
		border: 2px solid $main-color-one;
		background-color: $white;
		margin: 1px 0;
		&:hover, &:focus {
			background-color: $main-color-one;
			color: $white;
			text-decoration: underline;
		}
	}
	&.constrain {
        @include container;
    }
}