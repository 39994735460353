.image-cards {
	@include media(">=xs") {
		display: flex;
		margin: 0 -10px;
	}
	.item {
		margin: 10px;
		@include media(">=xs") {
			width: 50%;
		}
		.media {
			position: relative;
			overflow: hidden;
			height: 200px;
			img {
				position: absolute;
				max-width: none;
				width: auto;
				transform: translate(-50%,-50%);
				height: auto;
				min-height: 100%;
				right: auto;
				left: 50%;
				top: 50%;
				min-width: 100%;
			}
		}
		.message {	
			h2 {
				color: $main-color-one;
				font-family: $serif-font;
				text-transform: inherit;
				margin: 10px 0 0 0;
				font-size: rem(18);
				@include media(">=sm") {
					font-size: rem(24);
				}
			}
			.inner {
				@include media(">=md") {
					display: flex;
				}
				.overview {
					margin-top: 10px;
					flex-basis: 50%;
					font-size: rem(16);
					line-height: rem(26);
				}
				.hilites {
					margin-top: 10px;
					flex: 1;
					ul {
						@include reset-list;
						margin: 0;
						padding: 0 0 0 20px;
						font-weight: 700;
						li {
							position: relative;
							padding-left: 20px;
							margin-bottom: 10px;
							font-size: rem(14);
							line-height: rem(16);
							&:before {
								position: absolute;
								content: '';
								left: 0px;
								// top: calc(50% - 7px);
								top: rem(3);
								display: block;
								width: 8px;
								height: 10px;
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.376' height='13.835' viewBox='0 0 10.376 13.835'%3E%3Cpath id='Path_3520' data-name='Path 3520' d='M89.436,231.128v13.835l10.376-6.917Z' transform='translate(-89.436 -231.128)' fill='%234c4a4f'/%3E%3C/svg%3E%0A");
								background-size: contain;
								background-repeat: no-repeat;
							}
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	&.constrain {
        @include container;
    }
}