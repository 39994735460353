.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-image: url(../images/image.jpg);
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon1 {
    width: 574px;
    height: 242px;
    margin: 0;
    margin-left: -152px;
    @include media(">=28.125rem") {
        width: 668px;
        height: 342px;
        margin-left: -136px;
    }
    @include media(">=sm") {
        width: 700px;
        height: 350px;
        margin-left: -154px;
    }
}

.hex-wrap {
    width: 280px;
    height: 255px;
    margin: 0 auto;
    @include media(">=28.125rem") {
        height: 352px;
        width: 400px;
    }
}