.searchContainer {
    display: none;
    position: relative;
    background-color: $white;
    overflow: hidden;
    padding: 1px 5px;
    border: solid 10px $lt-grey;
    // margin-bottom: 10px;
    // @include media(">=md") {
    //     display: inline-flex;
    //     flex: 1 1 300px;
    //     width: 260px;
    //     position: absolute;
    //     right: 15px;
    //     top: rem(-46);
    //     border-radius: 30px;
    // }
    // @include media(">=91.875rem") {
    //     right: 0;
    // }
    .searchIcon {
            padding: 0.5rem;
            position: absolute;
            top: 0;
            left: 0;
            @include media(">=md") {

            }
        }
    .searchBox {
        height: rem(30);
        border: none;
        padding: 0.5rem 0.5rem 0.5rem 0;
        flex: 1;
        padding-left: 30px !important;
        padding-right: 5px;
        width: 100%;
        &:focus {
            @include focus;
        }
        @include media(">=md") {
            padding-left: 0;
        }
    }
    &.search-return {
        @include media(">=md") {
            width: 400px;
            max-width: 100%;
            position: relative;
            right: auto;
            top: auto;
        }
        @include media(">=91.875rem") {
            right: auto;
        }
        // .searchBox {
        //     height: rem(30);
        //     border: none;
        //     padding: 0.5rem 0.5rem 0.5rem 0;
        //     flex: 1;
        //     padding-left: 30px !important;
        //     padding-right: 5px;
        //     width: 100%;
        //     @include media(">=md") {
        //         padding-left: 0;
        //     }
        //     &:focus {
        //         outline: none;
        //     }
        // }
        .btn-primary {
            position: absolute;
            right: -2px;
            top: -3px;
            color: $white;
            background-color: $main-color-one;
            border-color: $main-color-one;
            &:hover {
                background-color: darken($main-color-one, 10%);
            }
        }
    }
}


.listItem {
    a {
        font-size: rem(16);
        font-weight: 700;
    }
    .teaser {
        display: block;
        font-size: rem(14);
    }
    hr {
        margin: 10px 0;
    }
}

.btn-link {
    color: $main-color-one;
    border: solid 1px $main-color-one;
    border-radius: 10px;
    padding: 3px 10px;
    margin-left: 10px;
    &:hover {
        text-decoration: none;
        color: $main-color-two;
        color: $white;
        background-color: $main-color-one;
    }
}