.program-finder {

	.form {
		width: 100%;
		text-align: center;
		.inner {
			.pf-search {
				margin-bottom: 5px;
				input {
					width: 100%;
					border-radius: 0;
					height: rem(40);
						margin: 0 0 1rem;
						padding: .5rem;
						width: 100%;
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						border: 1px solid $lt-grey;
						border-radius: 0;
						background-color: $white;
						font-family: inherit;
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.5;
						color: #141414;
				}
			}
			.select-items {
				display: block;
				margin: 0 -10px;
				@include media(">=sm") {
					display: flex;
					flex-wrap: wrap;
				}
				.select-item {
					margin: 0 10px;
					flex: 1;
					select {
						height: rem(40);
						margin: 0 0 1rem;
						padding: .5rem;
						width: 100%;
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						border: 1px solid $lt-grey;
						border-radius: 0;
						background-color: $white;
						font-family: inherit;
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.5;
						color: #141414;
						background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28119,119,119%29'></polygon></svg>");
						background-origin: content-box;
						background-position: right -1rem center;
						background-repeat: no-repeat;
						background-size: 9px 6px;
						padding-right: 1.5rem;
						&:focus {
							// @include focus;
						}
					}
				}
				.button-collection {
					margin: 0 10px;
				}
			}			
		}



		label {
			color: $white;
			font-size: rem(20);
			font-weight: 700;
			display: inline-block;
		}

		.button, button, input[type=button], input[type=reset], input[type=submit] {
			transition: all .2s ease-out;
			display: inline-flex;
			align-items: center;
			padding: 10px;
			cursor: pointer;
			height: rem(40);
			margin: 0 0 1rem;
			padding: .5rem;
			border-radius: 0;
			text-transform: uppercase;
			&.search {
				background-color: $main-color-one;
				border: 2px solid transparent;
				color: $white;
			}
			&.view-all {
				color: $white;
				background-color: $dk-grey;
				border: 2px solid transparent;
			}
			&:focus {
				// @include focus;
			}
		}
	}
}
