.hex-row {
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 60px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920 237.5' style='enable-background:new 0 0 1920 237.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23D3430D;%7D%0A%3C/style%3E%3Cpath class='st0' d='M-0.1,238H1920l0.2-119.2l-30.8-58.4c-0.2-0.7-0.9-1.1-1.6-1h-63.7c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58h-62.5 l-31.5-57.8c-0.2-0.6-0.7-1-1.3-1c-0.1,0-0.2,0-0.3,0h-63.7c-0.5,0-0.9,0.3-1.1,0.7l-31.6,58h-63.2c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58 h-62.5l-31.6-58.1c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8c-0.1,0-0.2,0-0.3,0c-0.6,0-1.1,0.4-1.2,1l-31.5,57.8h-63.2 c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58h-62.5l-31.3-57.5c-0.2-0.4-0.7-0.7-1.1-0.7h-63.2l-31.6-58c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.2-0.5,0.4-0.6,0.8l-31.5,57.8h-62.5l-31.6-58.1c-0.2-0.4-0.7-0.7-1.2-0.7h-63.8 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.2-0.5,0.4-0.6,0.8l-31.5,57.8h-63c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2 c-0.3,0.2-0.5,0.4-0.6,0.8L639.8,236h-62.5l-31.5-57.8c-0.2-0.6-0.7-1-1.2-1c-0.1,0-0.2,0-0.3,0h-63l-31.6-58.1 c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.6,0.2c-0.3,0.2-0.5,0.4-0.6,0.8l-31.5,57.8h-63 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0.1l-31.3-57.4L288.2,62c0.5-0.5,0.5-1.4,0-1.9L256.3,1.7c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.2-0.5,0.5-0.6,0.8l-31.3,57.5H95.1c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58H-0.2 L-0.1,238z'/%3E%3C/svg%3E%0A");
    background-repeat: repeat-x;
	background-position: top 1px center;
	&.bottom {
		top: auto;
		bottom: 0;
		background-position: top -1px center;
		background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920 237.5' style='enable-background:new 0 0 1920 237.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23D3430D;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1920.2,1L0,1l-0.2,119.2l30.8,58.4c0.2,0.7,0.9,1.1,1.6,1h63.7c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5l31.5,57.8 c0.2,0.6,0.7,1,1.3,1c0.1,0,0.2,0,0.3,0h63.7c0.5,0,0.9-0.3,1.1-0.7l31.6-58h63.2c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5l31.6,58.1 c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.4,1.2-1l31.5-57.8h63.2c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5L798.4,61 c0.2,0.4,0.7,0.7,1.1,0.7h63.2l31.6,58c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h62.5l31.6,58.1c0.2,0.4,0.7,0.7,1.2,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h63c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.6-0.8L1280.3,3h62.5l31.5,57.8 c0.2,0.6,0.7,1,1.2,1c0.1,0,0.2,0,0.3,0h63l31.6,58.1c0.2,0.4,0.7,0.7,1.2,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h63c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.5-0.1l31.3,57.4l-31.5,57.7c-0.5,0.5-0.5,1.4,0,1.9 l31.9,58.4c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.6-0.8l31.3-57.5h63.5 c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5L1920.2,1z'/%3E%3C/svg%3E%0A");
	}
}
&.grey {
	.hex-row {
		background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920 237.5' style='enable-background:new 0 0 1920 237.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EAEAEA;%7D%0A%3C/style%3E%3Cpath class='st0' d='M-0.1,238H1920l0.2-119.2l-30.8-58.4c-0.2-0.7-0.9-1.1-1.6-1h-63.7c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58h-62.5 l-31.5-57.8c-0.2-0.6-0.7-1-1.3-1c-0.1,0-0.2,0-0.3,0h-63.7c-0.5,0-0.9,0.3-1.1,0.7l-31.6,58h-63.2c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58 h-62.5l-31.6-58.1c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8c-0.1,0-0.2,0-0.3,0c-0.6,0-1.1,0.4-1.2,1l-31.5,57.8h-63.2 c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58h-62.5l-31.3-57.5c-0.2-0.4-0.7-0.7-1.1-0.7h-63.2l-31.6-58c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.2-0.5,0.4-0.6,0.8l-31.5,57.8h-62.5l-31.6-58.1c-0.2-0.4-0.7-0.7-1.2-0.7h-63.8 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.2-0.5,0.4-0.6,0.8l-31.5,57.8h-63c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2 c-0.3,0.2-0.5,0.4-0.6,0.8L639.8,236h-62.5l-31.5-57.8c-0.2-0.6-0.7-1-1.2-1c-0.1,0-0.2,0-0.3,0h-63l-31.6-58.1 c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.6,0.2c-0.3,0.2-0.5,0.4-0.6,0.8l-31.5,57.8h-63 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0.1l-31.3-57.4L288.2,62c0.5-0.5,0.5-1.4,0-1.9L256.3,1.7c-0.2-0.4-0.7-0.7-1.1-0.7h-63.8 c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.2-0.5,0.5-0.6,0.8l-31.3,57.5H95.1c-0.5,0-0.9,0.3-1.2,0.7l-31.6,58H-0.2 L-0.1,238z'/%3E%3C/svg%3E%0A");
		&.bottom {
			background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920 237.5' style='enable-background:new 0 0 1920 237.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EAEAEA;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1920.2,1L0,1l-0.2,119.2l30.8,58.4c0.2,0.7,0.9,1.1,1.6,1h63.7c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5l31.5,57.8 c0.2,0.6,0.7,1,1.3,1c0.1,0,0.2,0,0.3,0h63.7c0.5,0,0.9-0.3,1.1-0.7l31.6-58h63.2c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5l31.6,58.1 c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.4,1.2-1l31.5-57.8h63.2c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5L798.4,61 c0.2,0.4,0.7,0.7,1.1,0.7h63.2l31.6,58c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h62.5l31.6,58.1c0.2,0.4,0.7,0.7,1.2,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h63c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.6-0.8L1280.3,3h62.5l31.5,57.8 c0.2,0.6,0.7,1,1.2,1c0.1,0,0.2,0,0.3,0h63l31.6,58.1c0.2,0.4,0.7,0.7,1.2,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h63c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.5-0.1l31.3,57.4l-31.5,57.7c-0.5,0.5-0.5,1.4,0,1.9 l31.9,58.4c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.6-0.8l31.3-57.5h63.5 c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5L1920.2,1z'/%3E%3C/svg%3E%0A");
		}
	}
	.component-wrap {
		background-color: $lt-grey;
	}
}

.component-wrap {
	background-color: $main-color-one;
}