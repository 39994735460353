.hex-teaser {
    position: relative;
    display: block;
    overflow: hidden;
    @include media(">=sm") {
        padding-top: 0;
        border: none;
        display: flex;
        margin: 0;
    }
    .media {
        position: relative;
        z-index: 2;
        @include media(">=sm") {
            flex: 0 0 400px;
            width: 400px;
            order: 2;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .message {
        flex: 1;
        margin: 20px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // min-height: rem(280);
        .inner {
            padding: 20px;
            h2 {
                margin: 0 0 20px 0;
            }
            .detail {
                font-size: rem(16);
                line-height: rem(22);
           }
            .links {
                a {
                    display: block;
                    font-family: $serif-font;
                    font-size: rem(20);
                    font-weight: 700;
                    margin-top: 20px;
                    position: relative;
                    padding-left: 30px;
                    &:before {
                        position: absolute;
                        content: '';
                        left: 0px;
                        // top: calc(50% - 7px);
                        top: rem(3);
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.231' height='28.231' viewBox='0 0 28.231 28.231'%3E%3Cg id='Group_7130' data-name='Group 7130' transform='translate(-639.818 72.875) rotate(-45)'%3E%3Cpath id='Path_3500' data-name='Path 3500' d='M506.844,423.553l-1.916,1.916,11.973,2.394-2.394-11.973-1.916,1.916A13.555,13.555,0,0,0,491,421.119,13.552,13.552,0,0,1,506.844,423.553Z' transform='translate(0 0)' fill='%23cb4900'/%3E%3C/g%3E%3C/svg%3E%0A");
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    &:hover {
                        &:before {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.231' height='28.231' viewBox='0 0 28.231 28.231'%3E%3Cg id='Group_7130' data-name='Group 7130' transform='translate(-639.818 72.875) rotate(-45)'%3E%3Cpath id='Path_3500' data-name='Path 3500' d='M506.844,423.553l-1.916,1.916,11.973,2.394-2.394-11.973-1.916,1.916A13.555,13.555,0,0,0,491,421.119,13.552,13.552,0,0,1,506.844,423.553Z' transform='translate(0 0)' fill='%23045678'/%3E%3C/g%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }
    }
    &.constrain {
        @include container;
    }
}
