.buttons {
	@include media(">=xs") {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: 0 -10px;
	}
	a {
		display: inline-block;
		min-width: rem(300);
		text-align: center;
		font-size: rem(14);
		line-height: rem(14);
		font-weight: 700;
		padding: 20px;
        color: $white;
        background-color: $main-color-one;
        text-transform: uppercase;
        border: solid 2px transparent;
		@include media(">=xs") {
			flex: 1;
			margin: 10px;
        }
        &:hover, &:focus, &.active {
            border-color: $main-color-one;
            background-color: $white;
            color: $main-color-one;
        }
	}
	&.constrain {
        @include container;
    }
}