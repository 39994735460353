.hex-overlay {
    overflow: hidden;
    &.top {
        margin-bottom: -8px;
    }
    &.bottom {
        margin-top: -8px;
    }
    .inner {
        margin-right: -5px;
        margin-left: -5px;
        img {
            display: block;
            width: 100%;
        }
    }
}