.padding-top {
	padding-top: rem(30);
	@include media(">=sm") {
		padding-top: rem(60);
	}
}
.padding-bottom {
	padding-bottom: rem(30);
	@include media(">=sm") {
		padding-bottom: rem(60);
	}
}
.margin-top {
	margin-top: rem(30);
	@include media(">=sm") {
		margin-top: rem(60);
	}
}
.margin-bottom {
	margin-bottom: rem(30);
	@include media(">=sm") {
		margin-bottom: rem(60);
	}
}

.negative-margin-top {
	margin-top: 0;
	@include media(">=sm") {
		margin-top: rem(-30);
	}
}
.negative-margin-bottom {
	margin-bottom: 0;
	@include media(">=sm") {
		margin-bottom: rem(-30);
	}
}