.hero {
    position: relative;
    .media {
        position: relative;
        overflow: hidden;
        min-height: 100vh;
        @include media(">=xs") {
            min-height: auto;
        }
        @include media(">=xl") {
            min-height: 900px;
        }
        .overlay {
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0 auto;
            background: linear-gradient(transparent 0%, rgba(0,0,0,1) 95%);        
        }
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%); 
            height: 100%;    
            @include media(">=xs") {
                position: relative;
                transform: translate(-50%, 0%);
                width: 100%;
            }
            @include media(">=xl") {
                position: absolute;
                transform: translate(-50%,-50%);
                max-width: none;
                width: auto;
                height: auto;
                min-height: 100%;
                right: auto;
                left: 50%;
                top: 50%;
                min-width: 100%;
            }
        }
    }
    .message-wrap {
        position: absolute;
        top: 0;
        bottom: 20px;
        right: 0;
        left: 0;
        .inner {
            @include container;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            .message {
                color: $white;
                @include media(">=sm") {
                    width: 50%;
                }
                .title {
                    font-family: $serif-font;
                    font-size: rem(30);
                    line-height: rem(26);
                    margin-bottom: 20px;
                    font-weight: 400;
                    color: $white;
                    @include media(">=sm") {
                        font-size: rem(60); 
                        line-height: rem(50);
                        margin-bottom: 30px;
                    }
                    @include media(">=md") {
                        font-size: rem(80);
                        line-height: rem(70); 
                    }
                    @include media(">=xl") {
                        font-size: rem(100); 
                        line-height: rem(90);

                    }
                }
                .subtitle {
                    font-weight: 700;
                    @include media(">=sm") {
                        font-size: rem(26);
                    } 
                }
                .breadcrumbs {
                    padding: 20px 0;
                }
                .buttons {
                    a {
                        display: block;
                        text-align: center !important;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: rem(14);
                        margin-bottom: 10px;
                        padding: 20px;
                        color: $white;
                        background-color: $main-color-one;
                        max-width: 240px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .hex {
        display: none;
        @include media(">=md") {
            display: block;
            height: 100px;
            position: absolute;
            bottom: -78px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 80px;
            width: 100%;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920 237.5' style='enable-background:new 0 0 1920 237.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23000000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1920.2,1L0,1l-0.2,119.2l30.8,58.4c0.2,0.7,0.9,1.1,1.6,1h63.7c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5l31.5,57.8 c0.2,0.6,0.7,1,1.3,1c0.1,0,0.2,0,0.3,0h63.7c0.5,0,0.9-0.3,1.1-0.7l31.6-58h63.2c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5l31.6,58.1 c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.4,1.2-1l31.5-57.8h63.2c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5L798.4,61 c0.2,0.4,0.7,0.7,1.1,0.7h63.2l31.6,58c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h62.5l31.6,58.1c0.2,0.4,0.7,0.7,1.2,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h63c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.6-0.8L1280.3,3h62.5l31.5,57.8 c0.2,0.6,0.7,1,1.2,1c0.1,0,0.2,0,0.3,0h63l31.6,58.1c0.2,0.4,0.7,0.7,1.2,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2 c0.3-0.2,0.5-0.4,0.6-0.8l31.5-57.8h63c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.5-0.1l31.3,57.4l-31.5,57.7c-0.5,0.5-0.5,1.4,0,1.9 l31.9,58.4c0.2,0.4,0.7,0.7,1.1,0.7h63.8c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.6-0.8l31.3-57.5h63.5 c0.5,0,0.9-0.3,1.2-0.7l31.6-58h62.5L1920.2,1z'/%3E%3C/svg%3E%0A");
            background-repeat: repeat-x;
            background-position: bottom center;
            // position: absolute;
            // top: 0;
            // left: 0;
            // right: 0;
            // margin: 0 auto;
            // height: 60px;
            // background-image: url(../images/hex-overlay-top-orange.svg);
            // background-repeat: repeat-x;
            // background-position: bottom center;
        }
    }
    &.home, &.landing {
        .message-wrap {
            .inner {
                .message {
                    @include media(">=sm") {
                        width: 100%;
                    }
                    .subtitle {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    &.home {
        .message-wrap {
            .inner {
                justify-content: center;
                .message {
                    padding-top: 20px;
                    @include media(">=md") {
                        padding-top: 100px;
                        width: 50%;
                    }
                    .title {
                        text-align: center;
                        @include media(">=sm") {
                            text-align: left;
                        }
                    }
                    .subtitle {
                        text-align: center;
                        @include media(">=sm") {
                            text-align: left;
                        }
                    }
                    .buttons {
                        text-align: center;
                        display: block;
                        max-width: 400px;
                        margin: 0 auto;
                        @include media(">=sm") {
                            text-align: left;
                            margin: 0;
                        }
                        a {
                            margin: 0 auto 10px auto;
                            @include media(">=sm") {
                                text-align: left;
                                margin: 0 0 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &.landing {
        + .page-content {
            .page-inner {
                .sidebar {
                    padding-top: 100px;
                }
                .content-area {
                    padding-top: 100px;
                }
            }
        }
    }
    &.short {
        .media {
            min-height: 100px;
            @include media(">=xl") {
                min-height: 450px;
            }
            .overlay {
                display: none;
            }
        }
    }
}
