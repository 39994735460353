.image-teaser {
	position: relative;
    overflow: hidden;
    background-color: $main-color-one;
    // background-image: url(../images/bg-stripe.svg);
    // background-size: cover;
    // background-position: center center;
    // background-repeat: no-repeat;
	.image-teaser-wrapper {
		position: relative;
        @include media(">=sm") {
            padding: 0;
        }
		.image-teaser-inner {
			// position: relative;
            display: block;
            @include container;
            padding: 0;
			@include media(">=sm") {
				flex-flow: row nowrap;
				margin: 0 auto;
				display: -ms-flexbox;
				display: flex;
				justify-content: space-between;
                &.big-bottom {
                    padding-bottom: rem(100);
                }
			}
			.image-teaser-media-mobile {
				img {
					width: 100%;
				}
				@include media(">=sm") {
					display: none;
				}
			}
            .image-teaser-media {
				display:none;
				width: 100%;
				position: relative;
                z-index: 1;
				@include media(">=sm") {
					display: block;
					position: absolute;
					content: '';
					display: block;
                    margin: 0;
                    padding: 0;
					z-index: 0;
					max-width: 50%;
					top: 0;
					bottom: 0;
					left: auto;
					right: 0;
					overflow: hidden;
                    z-index: 2;
                    &:before {
                        display: block;
                        position: relative;
                        content: '';
                        background-image: url(../images/cta-overlay-1-orange.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        height: 100%;
                        z-index: 2;
                        margin-left: -3px;
                    }
                }	
                a {
                    display: block;
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: calc(50% - 50px);
                    left: calc(50% - 50px);
                    z-index: 10;
                    &:before {
                        display: block;
                        font-family: $icon-font;
                        content: '\f144';
                        font-size: 100px;
                        position: absolute;
                        top: calc(50% - 50px);
                        left: calc(50% - 50px);
                        color: $white;
                        z-index: 2;
                        text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
                    }
                    &:hover {
                        &:before {
                            color: $main-color-one;
                        }
                    }
                }
                .video {
                    display: block;
                    // &:before {
                    //     display: block;
                    //     font-family: $icon-font;
                    //     content: '\f144';
                    //     font-size: 100px;
                    //     position: absolute;
                    //     top: calc(50% - 50px);
                    //     left: calc(50% - 50px);
                    //     color: $white;
                    //     z-index: 2;
                    //     text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
                    // }
                    &:hover {
                        &:before {
                            color: $main-color-one;
                        }
                    }
                }
				img {
					display: inline-block;
					max-width: 100%;
					vertical-align: middle;
					border-style: none;
					@include media(">=sm") {
						position: absolute;
						// max-height: 110%;
						max-width: none;
						width: auto;
						transform: translate(-50%, -50%);
						height: auto;
						min-height: 100%;
						right: auto;
						left: 50%;
						top: 50%;
						min-width: 100%;
					}
				}
			}
			.image-teaser-body {
				-webkit-box-flex: 0;
				position: relative;
				z-index: 3;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-flow: column nowrap;
				flex-flow: column nowrap;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
                width: 100%;
                padding: 15px;
				@include media(">=sm") {
					min-height: rem(400);
					flex: 0 1 50%;
                    max-width: 50%;
                    padding-top: 20px;
                    padding-bottom: 20px;
					padding-right: 5rem;
				}
				.headgroup {
					color: $xlt-grey;
					.superhead {
						margin-bottom: 10px;
						font-family: $sans-serif-font;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: .15em;
						line-height: 1.5;
						font-size: 0.8125rem;
					}
					.headline {
                        position: relative;
						font-size: rem(30);
						line-height: rem(32);
						font-family: $sans-serif-font;
                        text-transform: uppercase;
						font-weight: 700;
                        // margin-bottom: 20px;
					}
				}
				.image-teaser-summary {
					color: $xlt-grey;
					margin-top: 20px;
					h2 {
						color: $white;
						margin: 0;
						padding: 0;
						font-size: rem(18);
						font-family: $serif-font;
					}
				}
				.image-teaser-button {
                    margin-top: 15px;
                    position: relative;
                    z-index: 10;
					a {
                        display: inline-block;
						border: 2px solid $xlt-grey;
						color: $dk-grey !important;
						padding: 10px 15px;
						font-size: rem(14);
						background-color: $xlt-grey;
                        font-weight: 700;
                        position: relative;
                        text-transform: uppercase;
                        font-weight: 700;
						&:hover {
							background-color: $main-color-one;
							color: $white !important;
							text-decoration: none;
							font-weight: 700;
						}
					}
				}
            }
		}
	}
	&.flip {
		.image-teaser-wrapper {
			&:before {
				margin: 0 -750px 0 auto;
			}
			.image-teaser-inner {
				.image-teaser-body {
					order: 2;
					padding-right: 0;
					@include media(">=sm") {
						padding-left: rem(20);
                    }
                    @include media(">=md") {
						padding-left: rem(80);
					}
				}
				.image-teaser-media {
					left: 0;
                    right: auto;
                    @include media(">=sm") {
                        &:before {
                            display: none;
                        }
                        &:after {
                            display: block;
                            position: relative;
                            content: '';
                            background-image: url(../images/cta-overlay-1-flip-orange.svg);
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: right;
                            height: 100%;
                            z-index: 2;
                            width: auto;
                            margin-left: 0;
                            margin-right: -2px;
                        }
                    }
				}
			}
		}
	}
	&.alt-flip {
		.image-teaser-wrapper {
			&:before {
				
			}
			.image-teaser-inner {
				.image-teaser-body {

				}
				.image-teaser-media {

                    @include media(">=sm") {
                        &:before {
                            
                        }
                        &:after {
							background-image: url(../images/cta-overlay-2-flip-orange.svg);
							margin-top: -1px;
                        }
                    }
				}
			}
		}
	}
	&.alt {
		.image-teaser-wrapper {
			&:before {
				
			}
			.image-teaser-inner {
				.image-teaser-body {

				}
				.image-teaser-media {

                    @include media(">=sm") {
                        &:before {
                            background-image: url(../images/cta-overlay-2-orange.svg);
							margin-top: -1px;
                        }
                    }
				}
			}
		}
	}
}
