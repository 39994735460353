footer {
	background-color: $grey;
	color: $white;
	padding: 60px 0;
	.wrapper {
		@include container;
		@include media(">=md") {
			display: flex;
			justify-content: space-between;
		}
		.foot-col {
			flex: 1;
			display: flex;
			justify-content: space-between;
			@include media(">=md") {
				margin-right: 20px;
				&:last-child {
					margin-right: 0;
				}
			}
			&.colz {
				flex-direction: column;
				justify-content: flex-start;
			}
			&.block {
				display: block;
			}
			.logo {
				img {
					max-width: 100%;
				}
			}
			.dir-links {
				font-size: rem(11);
				a {
					color: $white;	
				}
			}
			.policy-links {
				font-size: rem(11);
				a {
					color: $white;
				}
			}
			.campuses {
				margin-bottom: 10px;
				color: $main-color-one;
				font-weight: 700;
				text-transform: uppercase;
				font-size: rem(12);
				margin: 10px 0 10px 0;
				a {
					color: $white;
				}
			}
			.social-icons {
				margin: 20px 0 0 0;
				@include media(">=md") {
					margin: 60px 0 0 0;
				}
				a {
					display: inline-block;
					margin-right: 5px;
					&:last-child {
						margin-right: 0;
					}
				}
			}
			.foot-link-collection {
				@include media(">=300px") {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
				}
				.foot-links {
					flex: 0 0 50%;
					@include media(">=sm") {
						flex: 1;
					}
					@include media(">=1000px") {
						flex: 0 0 50%;
					}
					@include media(">=1200px") {
						flex: 1;
					}
					p {
						text-transform: uppercase;
						font-weight: 700;
					}
					ul {
						@include reset-list;
						li {
							a {
								display: inline-block;
								font-size: rem(12);
								font-weight: 700;
								line-height: rem(10);
								color: white;
							}
						}
					}
				}
			}
			.copyright {
				text-align: right;
				font-size: rem(11);
				margin: 20px 0 0 0;
			}
		}
	}
}