.inner-container {
    @include container;
}

.content-area {
    .inner-container {
        padding: 15px !important;
    }
}

.full-container {
    @include container;
}

.content-area {
    .full-container {
        padding-right: 0;
        padding-left: 0;
    }
}


.page-content {
    background-color: $white;
    .page-inner {
        @include container;
        @include media(">=md") {
            display: flex;
        }
        .sidebar {
            padding: 20px 0 0 0;
            @include media(">=md") {
                flex: 0 0 350px;
                -ms-flex: 0 0 350px;
                width: 350px;
                border-right: 2px solid $lt-grey;
                padding: 40px 20px 40px 0;
            }
        }
        .content-area {
            padding-bottom: 40px;
            @include media(">=md") {
                padding: 40px 0 40px 20px;
            }
        }
    }
}
