.icons {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: -10px;
	a {
		flex: 1;
		min-width: 200px;
		margin: 10px;
		text-align: center;
		font-size: rem(14);
		font-weight: 700;
		padding: 10px;
		color: $grey;
		border: solid 2px transparent;
		img {
			margin: 0 auto;
			width: 50px;
			max-height: 50px;
			margin-bottom: 10px;
		}
		&:hover {
			border-color: $grey;
		}
	}
	&.constrain {
        @include container;
    }
}