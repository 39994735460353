.upcoming-events {
    position: relative;
    display: block;
    overflow: hidden;
    @include media(">=sm") {
        max-width: 900px;
        margin: 0 auto;
        padding-top: 0;
        border: none;
        display: flex;
    }
    h2 {
        margin: 0 0 20px 0;
    }
    .media {
        position: relative;
        z-index: 2;
        @include media(">=sm") {
            flex: 0 0 300px;
            width: 300px;
            order: 2;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .message {
        flex: 1;
        margin: 20px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // min-height: rem(280);
        .inner {
            .item {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: flex;
                    span {
                        display: block;
                        &.date {
                            flex-basis: rem(100);
                            border-right: 2px solid $main-color-one;
                            .month {
                                font-weight: 700;
                                font-size: rem(15);
                                line-height: rem(15);
                                color: $text-color;
                                text-align: center;
                                text-transform: uppercase;
                            }
                            .day {
                                text-align: center;
                                font-family: $serif-font;
                                font-weight: 700;
                                font-size: rem(30);
                                line-height: rem(30);
                            }
                        }
                        &.details {
                            padding-left: 20px;
                            color: $text-color;
                            position: relative;
                            font-weight: 700;
                            .title {
                                padding-left: 30px;
                                &:before {
                                    position: absolute;
                                    content: '';
                                    left: 20px;
                                    // top: calc(50% - 7px);
                                    top: rem(3);
                                    display: block;
                                    width: 20px;
                                    height: 20px;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.231' height='28.231' viewBox='0 0 28.231 28.231'%3E%3Cg id='Group_7130' data-name='Group 7130' transform='translate(-639.818 72.875) rotate(-45)'%3E%3Cpath id='Path_3500' data-name='Path 3500' d='M506.844,423.553l-1.916,1.916,11.973,2.394-2.394-11.973-1.916,1.916A13.555,13.555,0,0,0,491,421.119,13.552,13.552,0,0,1,506.844,423.553Z' transform='translate(0 0)' fill='%23cb4900'/%3E%3C/g%3E%3C/svg%3E%0A");
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                }
                            }
                            .location-time {
                                .location {
                                    display: inline;
                                }
                                .time {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
