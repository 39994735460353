@import 'navigation/main_nav_wrap';
@import 'navigation/navigation_logo';
@import 'navigation/navigation_utility';
@import 'navigation/navigation_jump';
@import 'navigation/navigation_search';

.navigation {
	display: none;
	.navigation-inner {
		@include media(">=md") {
			background-color: $white;
			position: relative;
			display: block;
			margin-top: 0;
			display: flex;
			min-height: 500px;
			height: 50vh;
			// overflow: hidden;
			.image-wrapper {
				flex: 1;
				position: relative;
				background-image: url(../images/bg-main-nav.png);
				background-size: cover;		
			}
			.nav-wrapper {
				flex: 1;	
				.main-nav-wrap {
					background-color: $white;
					.nav-heading {
						font-size: rem(30);
					}
				}	
			}
		}
		.nav-heading {
			font-family: $nav-font;
			font-size: rem(26);
			padding-bottom: 20px;
			text-align: left;
			padding: 20px 0 0 15px;
			text-transform: uppercase;
			background-color: $xlt-grey;
			position: relative;
			z-index: 3;
			@include media(">=md") {
				background-color: transparent;
			}
		}
	}
}



ul.main-nav {
    list-style: none;
    padding-left: 0;
    position: relative;
    padding: 0;
    margin: 0;
	z-index: 1999;
	@include media(">=md") {
        text-align: right;
		margin-bottom: 0;
		width: rem(230);
		background-color: $lt-grey;
		padding-bottom: 30px;
		height: calc(100% - 140px);
		// &:after {
		// 	display: block;
		// 	content: '';
		// 	width: 2px;
		// 	height: 100%;
		// 	background-color: $lt-grey;
		// 	position: absolute;
		// 	right: 5px;
		// 	top: 0;
		// }
	}
	// TOP LEVEL NAV LINKS @ MOBILE
	li {
		position: relative;
		z-index: 2001;
		a {
			display: block;
			font-size: 20px;
			padding: 14px 15px;
			height: auto;
			text-decoration: none;
			background-color: $xlt-grey;
			color: $main-color-one;
			font-family: $nav-font;
			transition: none;
			font-weight: 700;
			border-bottom: solid 1px $grey;
			&:hover, &:focus {
				text-decoration: underline;
			}
			@include media(">=md") {
				font-size: rem(16);
				// height: 2rem;
				padding: rem(10) rem(12) 0 rem(12) ;
				// color: $black;
				border-bottom: none;
				&:hover, &:focus {
					text-decoration: none;
				}
			}	
			@include media(">=lg") {
				font-size: .9375rem
			}	
		}
		&.toggle-main-sub {
			display: block;
			@include media(">=md") {
				display: none;
			}
		}
		ul {
			display: none;
			list-style: none;
			padding-left: 0;
			padding: 0;
			margin: 0;
			&.open {
				display: none;
			}	
			// SECOND LEVEL NAV LINKS @ MOBILE
			li {
				a {
					font-family: $sans-serif-font;
					font-size: 18px;
					line-height: 20px;
					color: $dk-grey;
					font-weight: 700;
					background-color: $xlt-grey;
					border-color: $main-color-one;
					&:hover {
						color: $main-color-one;
					}
				}
				&.search-toggle {
					display: none;
				}
			}		
		}
		// TOP LEVEL NAV LINKS @ DESKTOP (li)
		@include media(">=md") {
			position: relative;
			// text-align: left;
			display: inline-block;
			border-bottom: none;
			a {
				// border-bottom: none;
				padding: 0 30px 0 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: rem(60);
				font-size: rem(26);
				// border-bottom: 2px solid transparent;
				background-color: transparent;
			}
			// TOP LEVEL NAV LINKS HOVER @ DESKTOP (li)
			display: block;
			&:hover {
				a {
					position: relative;
					color: $black;
					position: relative;
					background-color: transparent;
					z-index: 2001;
					position: relative;
					&:before {
						display: block;
						content: '';
						width: 60%;
						height: 2px;
						background-color: $main-color-one;
						position: absolute;
						bottom: 0;
						right: 30px;
					}
					&:after {
						display: block;
						content: '';
						width: 20px;
						height: 30px;
						background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.58 25.77'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23eaeaea;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1-2'%3E%3Cpath id='Path_3052' data-name='Path 3052' class='cls-1' d='M16.6,13,0,25.77V0Z' transform='translate(-0.03 -0.01)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
						background-size: cover;
						background-color: transpaerent;
						position: absolute;
						top: 20px;
						right: -12px;
					}
				}
				// SECOND LEVEL NAV LINKS @ DESKTOP
				> ul {
					display: block;
					position: absolute;
					width: rem(300);
					top: 10px;
					right: rem(-300);
					z-index: 2000;
					li {
						width: 100%;
						background-color: transparent;
						display: block;
						text-transform: none;
						margin: 0;
						&:last-child {
							border-bottom: none;
						}
						&.search-toggle {   
							display: inline-block
						}
						a {
							height: rem(48) !important;
							font-family: $sans-serif-font;
							font-size: rem(18);
							line-height: rem(22);
							padding: 10px 10px 10px 20px;
							text-align: left;
							color: $black;
							background-color: $white;
							font-weight: 400;
							border-color: transparent;
							overflow: hidden;
							transition: all .5s ease;
							&:after {
								opacity: 0;
								display: block;
								content: '';
								width: 8px;
								height: 12px;
								background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16.7 25.8' style='enable-background:new 0 0 16.7 25.8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23CB4900;%7D%0A%3C/style%3E%3Cpath id='Path_3052' class='st0' d='M16.7,12.9L3.8,25.8L0,22l9.1-9.1L0,3.8L3.8,0L16.7,12.9z'/%3E%3C/svg%3E%0A");
								background-size: cover;
								background-color: transparent;
								position: absolute;
								top: 19px;
								left: 0px;
								transition: all 200ms cubic-bezier(.74,.04,.82,.25);
							}
							&:before {
								display: block;
								content: '';
								width: 100%;
								height: 2px;
								background: linear-gradient(90deg, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 95%);
								position: absolute;
								bottom: 0px;
								left: 20px;
							}
							&:hover {
								background-color: $white !important;
								color: $main-color-one;
								padding: 10px 10px 10px 40px;
								transition: all .5s ease;
								&:after {
									opacity: 1;
									left: 20px;
									transition: all 200ms cubic-bezier(.74,.04,.82,.25);
								}
							}
						}
						&:last-child {
							a {
								&:before {
									display: none;
								}
							}
						}
					}
				}
			}
			
		}	
		// @include media(">=md") {
		// 	&:nth-child(2) {
		// 		> ul {
		// 			top: rem(-55);
		// 		}
		// 	}
		// 	&:nth-child(3) {
		// 		> ul {
		// 			top: rem(-115);
		// 		}
		// 	}
		// 	&:nth-child(4) {
		// 		> ul {
		// 			top: rem(-175);
		// 		}
		// 	}
		// 	&:nth-child(5) {
		// 		> ul {
		// 			top: rem(-235);
		// 		}
		// 	}
		// 	&:nth-child(6) {
		// 		> ul {
		// 			top: rem(-295);
		// 		}
		// 	}
		// 	&:nth-child(7) {
		// 		> ul {
		// 			top: rem(-355);
		// 		}
		// 	}
		// }
	}	
}




.mob-nav-wrap {
    background-color: $white;
	display: block;
	@include media(">=md") {
		// display: none;
	}
	.mob-nav {
		// display: block;
		// height: 60px;
		// background-color: $main-color-one
	}
}
a.search-trigger {
    position: absolute;
    z-index: 3;
    top: 0;
    right:56px;
    height: 60px;
    width: 56px;
    white-space: nowrap;
    color: $white;
    text-indent: -30px;
    padding-top: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
	background-color: $dk-grey;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cpath id='Path_3368' data-name='Path 3368' d='M2.75,9.625A6.808,6.808,0,0,1,9.625,2.75,6.808,6.808,0,0,1,16.5,9.625,6.808,6.808,0,0,1,9.625,16.5,6.808,6.808,0,0,1,2.75,9.625ZM19.663,21.587a1.361,1.361,0,0,0,1.925-1.925L17.325,15.4A9.414,9.414,0,0,0,19.25,9.625,9.566,9.566,0,0,0,9.625,0,9.566,9.566,0,0,0,0,9.625,9.566,9.566,0,0,0,9.625,19.25,9.414,9.414,0,0,0,15.4,17.325Z' fill='%23fff'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-size: 33px 33px;
	background-position: 12px 15px;
	&:hover {
		text-decoration: none;
	}

}



a.nav-trigger {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    height: 60px;
    width: 56px;
    white-space: nowrap;
    color: $white;
    text-indent: -30px;
    padding-top: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
	background-color: $main-color-one;
	&:hover {
		text-decoration: none;
	}

}


// HAMBURGER NAV TRIGGER
.nav-trigger {
	position: relative;
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 28.7 25.2' style='enable-background:new 0 0 28.7 25.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath id='_20180825_Campus_Drone_Images_DP_9214_2_15' class='st0' d='M27.3,12.4L21,1.4c0-0.1-0.1-0.1-0.2-0.1H8 c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1l-6.4,11c0,0.1,0,0.2,0,0.3l6.4,11c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0 h12.7c0.1,0,0.2-0.1,0.2-0.1l6.4-11c0,0,0.1-0.1,0.1-0.2C27.4,12.5,27.4,12.5,27.3,12.4z'/%3E%3C/svg%3E%0A");
	// transition: none;
	background-repeat: no-repeat;
	background-size:46px;
	background-position: 5px 10px; 
	span {
		position: absolute;
		width: 22px;
		height: 4px;
		background-color: $main-color-one;
		border-radius: 1.5px;
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		-webkit-transition: background-color .2s;
		-moz-transition: background-color .2s;
		transition: background-color .2s;
		&:after, &:before {
			position: absolute;
			width: 22px;
			height: 4px;
			background-color: $main-color-one;
			border-radius: 1.5px;
			content: '';
			top: 0;
			left: 0;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-transition: -webkit-transform .2s;
			-moz-transition: -moz-transform .2s;
			transition: transform .2s;
		}
		&:before {
			-webkit-transform: translateY(-6px);
			-moz-transform: translateY(-6px);
			-ms-transform: translateY(-6px);
			-o-transform: translateY(-6px);
			transform: translateY(-6px);
		}
		&:after {
			-webkit-transform: translateY(6px);
			-moz-transform: translateY(6px);
			-ms-transform: translateY(6px);
			-o-transform: translateY(6px);
			transform: translateY(6px)
		}
	}
}

 
// HAMBURGER NAV TRIGGER WHEN OPEN
.nav-is-visible {
	.nav-trigger {
		span {
			background-color: transparent;
			&:before, &:after {
				background-color: $main-color-one;
			}
			&:before {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}		
			&:after {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
			}	
		}
	} 
} 

// SECOND LEVEL NAV TOGGLE ICONS FOR MAIN NAV

ul {
	&.main-nav {
		li {
			.toggle-main-sub {
				background-color: transparent;
				color: $white;
				cursor: pointer;
				display: inline-block;
				font-size: inherit;
				line-height: inherit;
				padding: 10px 15px;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 100;
				// border-left: 1px solid $white;
				height: 51px;
				border-bottom: none;
				&:before {
					display: inline-block;
					font-family: $icon-font;
					font-weight: 400;
					font-size: 26px;
					content: '\f078';
					color: $grey;
					transition: all .5s ease;
				}
				&.menu-visible {
					background-color: $xlt-grey;
					border-left: 1px solid $grey;
					border-bottom: 1px solid $xlt-grey;
					height: 59px;
					&:before {
						color: $main-color-one;
						transform: rotate(-180deg);
						transition: all .5s ease;
					}			
					+ a {
						background-color: $lt-grey;
						color: $main-color-one;
						@include media(">=md") {
							background-color: transparent;
						}
					}		
				}
				@include media(">=md") {
					display: none;
				}
			}
		}
	}
}


// TABBING STYLES FOR KEYBOARD ONLY USERS

.main-nav {
	li {
		background-color: transparent;
		&.active-menu-container {
			background-color: $lt-grey;
			a {
				color: $black !important;
			}
			
		}
		a {
			&:focus {
				// background-color: $lt-grey;
				// color: $black;			
				// outline: none;
				// border: 2px solid red;
			}
		}
		> ul {
			&.show-menu {
				 display: block;
				 position: absolute;
				 top: rem(42);
				 left: 0;
				 width: rem(240) !important;
				 z-index: 2000;
				 background-color: transparent;
				 li {	
					 width: 100%;
					//  border-radius: 0;
					 a {
						 height: rem(34);
						 padding: .3125rem 0 .3125rem .625rem;	
						 color: $black;
						 background-color: $lt-grey !important;
						 text-align: left;		
						 font-size: rem(14);
						 &:focus {
							 background-color: $white !important;;				
							 
						 }
					 }
					 &:last-child {
						 a {
							 text-align: left;
						 }
					 }
				}
			}
		}
	}
}