.text-buttons {
	@include media(">=xs") {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: -10px;
	}
	a {
		display: block;
		min-width: rem(300);
		text-align: center;
		font-family: $serif-font;
		font-size: rem(32);
		line-height: rem(32);
		font-weight: 700;
		padding: 10px;
		color: $grey;
		border-top: 2px solid $main-color-one;
		@include media(">=xs") {
			flex: 1;
			margin: 10px;
		}
		&:first-child {
			border-top: none
		}
		@include media(">=38.75rem") {
			border-top: none;
			border-left: 2px solid $main-color-one;
			&:first-child {
				border-left: none
			}
			&:hover {
				border-color: $grey;
			}
		}
		@include media(">=sm") {
			// font-size: rem(26);
			// line-height: rem(26);
		}
		@include media(">=md") {
			// font-size: rem(32);
			// line-height: rem(32);
		}
	}
	&.constrain {
        @include container;
    }
}