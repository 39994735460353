ul {
	&.breadcrumbs {
		@include reset-list;
		background-color: transparent;
		display: block;
		text-align: left;
		position: relative;
		z-index: 100;
		font-family: $serif-font;
		li {
			display: inline-block;
			margin: 0;
            padding: 0;
            color: $white;
            font-size: rem(12);
            line-height: rem(12);
            text-transform: uppercase;
            font-weight: 400;
			a, a:visited {
				text-transform: uppercase;
				text-decoration: none;
				font-size: rem(14);
				line-height: rem(14);
				color:$white;
                padding: 0;
                font-weight: 700;
				&:hover, &:active {
					color:$xlt-grey;
					text-decoration: underline;
				}
			}
		}
		>li+li:before {
			padding: 0 5px 0 2px;
			font-family: $icon-font;
			font-size: 12px;
			font-weight: 300;
			color: $white;
			content: '\f054';
			color: $main-color-one;
		}
	}
}