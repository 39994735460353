.two-columns {
	@include container;
	@include media(">=sm") {
		.two-columns-inner {
			margin: auto -10px;
			display: flex;
			flex-wrap: wrap;
			.column-one {
				flex: 1;
				margin: auto 10px;
				max-width: 50%;
			}
			.column-two {
				flex: 1;
				margin: auto 10px;
				max-width: 50%;
			}
		}
	}
	&.constrain {
        @include container;
    }
}
