ul.utility-triggers {
    list-style: none;
    text-align: center;
    margin: 0;
    position: relative;
    width: 100%;
    padding: 10px 0;
    // border-bottom: 1px solid $white;
	background-color: $main-color-one;
	display: flex;
	justify-content: space-around;
    @include media(">=md") {
        border-bottom: none;
        padding: 0;
        text-align: center;
        padding: 0;
    }
    li {
        list-style: none;
        padding-left: 0;
        position: relative;
        display: inline-block;
		flex: 1;
        &:nth-child(2) {
            &:after {
                display: none;
            }
        }
        @include media(">=420px") {
            &:nth-child(2) {
                &:after {
                    display: block;
                }
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        a {
			height: 70px;
            padding: 8px 12px;
            text-transform: uppercase;
            background-color: $main-color-one;            
            color: 
            $white;
            font-size: rem(11);
            display: flex;
			flex-direction: column;
			justify-content: flex-end;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                display: block;
                background-color: $white;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0;
                transition: all .3s ease-in-out;
                width: 100%;
                z-index: -1;
            }
			&.info, &.apply, &.request {
				position: relative;
				&:after {
					content: '';
					display: block;
					width: 25px;
					height: 33px;
					position: absolute;
					top: 8px;
					right: 0;
					left: 0;
					margin: 0 auto;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34.92' height='47.461' viewBox='0 0 34.92 47.461'%3E%3Cg id='Group_7034' data-name='Group 7034' transform='translate(0)'%3E%3Cpath id='Path_3410' data-name='Path 3410' d='M9399.128,845.433a11.14,11.14,0,1,0,11.142,11.136A11.155,11.155,0,0,0,9399.128,845.433Z' transform='translate(-9381.567 -845.433)' fill='%23fafafa'/%3E%3Cpath id='Path_3411' data-name='Path 3411' d='M9189.842,1658.5h34.92v-4.759a17.46,17.46,0,1,0-34.92,0Z' transform='translate(-9189.842 -1611.039)' fill='%23fafafa'/%3E%3C/g%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-position: center top;
					background-size: contain;
					transition: all .3s ease-in-out;
				}		
			}
			&.apply {
				position: relative;
				&:after {
					width: 33px;
					background-image: url("data:image/svg+xml,%3Csvg id='Compose' xmlns='http://www.w3.org/2000/svg' width='47.172' height='47.172' viewBox='0 0 47.172 47.172'%3E%3Crect id='Rectangle_324' data-name='Rectangle 324' width='47.172' height='47.172' transform='translate(0 0)' fill='none'/%3E%3Cpath id='Path_110' data-name='Path 110' d='M24.04,9.072,7.257,26.307,0,47.172l20.864-6.8L37.647,23.586ZM45.358,7.711l-5.9-5.9a5.5,5.5,0,0,0-8.164,0L26.307,6.8,39.915,20.864l5.443-5.443a5.749,5.749,0,0,0,1.814-4.082A5.6,5.6,0,0,0,45.358,7.711Z' transform='translate(0 0)' fill='%23fafafa'/%3E%3C/svg%3E%0A");
				}
			}
			&.request {
				position: relative;
				&:after {
					width: 33px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46.626' height='46.627' viewBox='0 0 46.626 46.627'%3E%3Cg id='Information' transform='translate(0 0)'%3E%3Crect id='Rectangle_280' data-name='Rectangle 280' width='46.626' height='46.626' transform='translate(0 0.001)' fill='none'/%3E%3Cpath id='Union_6' data-name='Union 6' d='M0,46.626V40.8H5.831V17.485H0V11.662H11.654V40.8h5.831v5.821ZM2.916,4.381A4.369,4.369,0,1,1,7.285,8.747,4.374,4.374,0,0,1,2.916,4.381Z' transform='translate(14.571 0)' fill='%23fafafa'/%3E%3C/g%3E%3C/svg%3E%0A");
				}
			}
			&:hover {
				text-decoration: none;
				color: $main-color-one;
                &:before {
                    top: 0;
                }
				&:after {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34.92' height='47.461' viewBox='0 0 34.92 47.461'%3E%3Cg id='Group_7034' data-name='Group 7034' transform='translate(0)'%3E%3Cpath id='Path_3410' data-name='Path 3410' d='M9399.128,845.433a11.14,11.14,0,1,0,11.142,11.136A11.155,11.155,0,0,0,9399.128,845.433Z' transform='translate(-9381.567 -845.433)' fill='%23d3430d'/%3E%3Cpath id='Path_3411' data-name='Path 3411' d='M9189.842,1658.5h34.92v-4.759a17.46,17.46,0,1,0-34.92,0Z' transform='translate(-9189.842 -1611.039)' fill='%23d3430d'/%3E%3C/g%3E%3C/svg%3E%0A");	
					transition: all .3s ease-in-out;
				}
				&.apply {
					&:after {
						width: 33px;
						background-image: url("data:image/svg+xml,%3Csvg id='Compose' xmlns='http://www.w3.org/2000/svg' width='47.172' height='47.172' viewBox='0 0 47.172 47.172'%3E%3Crect id='Rectangle_324' data-name='Rectangle 324' width='47.172' height='47.172' transform='translate(0 0)' fill='none'/%3E%3Cpath id='Path_110' data-name='Path 110' d='M24.04,9.072,7.257,26.307,0,47.172l20.864-6.8L37.647,23.586ZM45.358,7.711l-5.9-5.9a5.5,5.5,0,0,0-8.164,0L26.307,6.8,39.915,20.864l5.443-5.443a5.749,5.749,0,0,0,1.814-4.082A5.6,5.6,0,0,0,45.358,7.711Z' transform='translate(0 0)' fill='%23d3430d'/%3E%3C/svg%3E%0A");
					}
				}
				&.request {
					&:after {
						width: 33px;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46.626' height='46.627' viewBox='0 0 46.626 46.627'%3E%3Cg id='Information' transform='translate(0 0)'%3E%3Crect id='Rectangle_280' data-name='Rectangle 280' width='46.626' height='46.626' transform='translate(0 0.001)' fill='none'/%3E%3Cpath id='Union_6' data-name='Union 6' d='M0,46.626V40.8H5.831V17.485H0V11.662H11.654V40.8h5.831v5.821ZM2.916,4.381A4.369,4.369,0,1,1,7.285,8.747,4.374,4.374,0,0,1,2.916,4.381Z' transform='translate(14.571 0)' fill='%23d3430d'/%3E%3C/g%3E%3C/svg%3E%0A");
					}
				}
			}	
			span {
				display: block;
				padding-top: 20px;
			}
			@include media(">=md") {
				font-size: rem(12);
			}
        }
        @include media(">=md") {
            &:after {
                display: none;
            }
            &:nth-child(2) {
                &:after {
                    display: none;
                }
            }
        }
    }
}



ul.utility {
	display: none;
	list-style: none;
	background-color: $main-color-one;
	// padding: 0 20px 20px 20px;
	padding: 0;
	margin: 0;
	// margin-bottom: rem(20);
	font-family: $condensed-font;
	.nav-heading {
		background-color: transparent !important;
		color: $white;
	}
	li {
        // border-bottom: 2px solid $sidebar-nav-color-one;
        border-bottom: 2px solid $white;
		&:last-child {
			margin-bottom: 0;
			border: none;
		}
		a, a:visited {
			text-decoration: none;
			background-color: $main-color-one;
			color: $white;
			display: block;
			padding: 15px;
            font-weight: 700;
            text-decoration: none !important;
			&:hover, &:active {
				background-color: $white;
				color: $main-color-one;
				text-decoration: underline !important;
            }
            &:focus {
				@include focus;
			}
		}
		&.utility-has-sub {
			position: relative;
			a.toggle-utility-sub {
				background-color: $sidebar-nav-color-one;
				color: $white;
				display: inline-block;
				font-size: inherit;
				line-height: inherit;
				padding: 18px;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 100; 
				height: 54px;
				border-bottom: none;
				&:hover {
					background-color: $sidebar-nav-color-two;
					&:before {
						color: $white;
					}
				}
				&:focus {
					@include focus;
				}
				&:before {
					display: inline-block;
					font-family: $icon-font;
					font-weight: 700;
					content: '\f078';
					color: $white;
					transition: all 0.5s ease;
				}
				&.menu-visible {
					background-color: darken($sidebar-nav-color-two, 15%);
					&:before {
						display: inline-block;
						font-family: $icon-font;
						font-weight: 700;
						content: '\f078';
						color: $white;
						transform: rotate(-180deg);
						transition: all 0.5s ease;
					}
					&:hover {
						background-color: lighten($sidebar-nav-color-two, 10%);
					}
					+ a {
						background-color: $sidebar-nav-color-two !important;
                        color: $white !important;
                        &:hover {
                            background-color: darken($sidebar-nav-color-two, 10%) !important;
                        }
					}
				}
			}
			a:nth-child(2)  {
				padding-right: 50px;
			}
		}
		ul {
			display: none;
			list-style: none;
			padding-left: 0;
			margin: 0;
			li {
				&.utility-has-sub {
					a.toggle-utility-sub {
                        background-color: darken($sidebar-nav-color-two, 30%); 
						&:before {
							color: $white;
						}
						&:hover {
							background-color: lighten($sidebar-nav-color-two, 10%); 
						}
						&.menu-visible {
							background-color: darken($sidebar-nav-color-two, 30%); 
							&:hover {
								background-color: lighten($sidebar-nav-color-two, 10%); 
							}
						}
					}
				}
				a, a:visited  {
                    // background-color: darken($sidebar-nav-color-two, 15%); 
                    background-color: darken($sidebar-nav-color-two, 15%);
					&:hover, &:active {
						background-color: darken($sidebar-nav-color-two, 25%); 
					}
				}
				ul {
					li {
						a, a:visited  {
							background-color: darken($sidebar-nav-color-two, 30%); 
							color: $white;
							&:hover, &:active {
								background-color: darken($sidebar-nav-color-two, 40%); 
							}
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

