.stats {
	background-color: $main-color-one;
	@include media(">=xs") {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -10px;
	}
	.item {
		min-width: rem(300);
		text-align: center;
		font-size: rem(32);
		line-height: rem(32);
		font-weight: 700;
		padding: 10px;
		color: $white;
		border-top: 2px solid $white;
		@include media(">=xs") {
			flex: 1;
			margin: 10px;
		}
		&:first-child {
			border-top: none
		}
		@include media(">=38.75rem") {
			border-top: none;
			border-left: 2px solid $white;
			&:first-child {
				border-left: none
			}
			&:hover {
				// border-color: $grey;
			}
		}
		@include media(">=sm") {
			// font-size: rem(26);
			// line-height: rem(26);
		}
		@include media(">=md") {
			// font-size: rem(32);
			// line-height: rem(32);
		}
		.superhead {
			font-size: rem(14);
			line-height: rem(14);
			font-weight: 700;
		}
		.title {
			font-family: $serif-font;
			font-size: rem(28);
			line-height: rem(28);
			font-weight: 700;
			margin: 5px 0;
		}
		.detail {
			font-size: rem(16);
			line-height: rem(16);
			font-weight: 400;
		}
	}
	&.constrain {
        @include container;
    }
}

&.grey {
	.stats {
		background-color: $lt-grey;
		.item {
			color: $text-color;
			border-color: $text-color;
		}
	}
}