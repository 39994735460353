.hex-quote {
    position: relative;
    display: block;
    border: 2px solid $main-color-one;
    overflow: hidden;
    @include media(">=sm") {
        padding-top: 0;
        border: none;
        display: flex;
        margin: 0;
    }
    .media {
        position: relative;
        z-index: 2;
        @include media(">=sm") {
            flex: 0 0 400px;
            width: 400px;
            order: 2;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    .quote {
        flex: 1;
        margin: 20px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // min-height: rem(280);
        @include media(">=sm") {
            border-top: solid 2px $main-color-one;
            border-bottom: solid 2px $main-color-one;
            border-left: solid 2px $main-color-one;
            border-right: solid 2px transparent;
            &:after {
                display: block;
                content: '';
                width: 200px;
                position: absolute;
                top:-2px;
                bottom: -2px;
                right: -200px;
                border-top: solid 2px $main-color-one;
                border-bottom: solid 2px $main-color-one;
                border-right: solid 2px $main-color-one;
            }
        }
        .inner {
            padding: 20px;
            .text {
                display: inline-block;
                position: relative;
                font-size: rem(22);
                line-height: rem(30);
                color: $grey;
                font-weight: 700;
                padding: 0 0 0 60px;
                @include media(">md") {
                    font-size: rem(32);
                    line-height: rem(36);
                    padding: 0 rem(70) 0 60px;
                }
                &:before {
                    display: block;
                    position: absolute;
                    top: -5px;
                    left: 0;                    
                    content: '';
                    background-image: url(../images/quotes.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 50px;
                    height: 40px;
                    @include media(">md") {
                        font-size: rem(60);
                    }
                }
                // &:after {
                //     font-family: $icon-font;
                //     content: '\f10e';
                //     font-weight: 700;
                //     font-size: rem(26);
                //     color: $main-color-one;
                //     position: absolute;
                //     padding-left: 10px;
                //     @include media(">md") {
                //         font-size: rem(60);
                //     }
                // }
            }
            .author {
                display: inline-block;
                color: $main-color-one;
                font-weight: 700;
                font-size: rem(18);
                line-height: rem(20);
                margin-top: 20px;
                padding-left: 60px;
                @include media(">md") {
                    font-size: rem(22);
                    line-height: rem(24);
                }
            }
        }
    }
    &.constrain {
        @include container;
    }
}
