// Main Contaier
@mixin container {
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  @include media(">=91.875rem") {
    padding: 0;
  }
}

@mixin focus {
  outline: 2px solid $main-color-one !important;
}


// Multi-Line Truncate
@mixin multiLineEllipsis($lineHeight: 1rem, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount; 
    text-align: justify;
    margin-right: 0;
    padding-right: rem(16);
    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: rem(16);
      height: rem(16);
      margin-top: rem(5);
      background: $bgColor;
    }
  }
  
  



// Reset List
@mixin reset-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

// Truncate Text
@mixin truncate-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

// Truncate Text Scroll
@mixin truncate-text-scroll {
    display: block;
    overflow: scroll;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

@mixin media($conditions...) {
  @for $i from 1 through length($conditions) {
    $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
  }

  $branches: get-query-branches($conditions);
  $query: '';

  @each $branch in $branches {
    @if (str-length($query) != 0) {
      $query: $query + ', ';
    }

    $query: $query + $branch;
  }

  @media #{$query} {
    @content;
  }
}