
.important-dates {
    h2 {
        text-align: center;
    }
    .controls {
        margin-bottom: 20px;
        .buttons {
            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 0;
                min-width: 180px !important;
                padding: 10px;
            }
        }
    }
}




